.profile-container {
  display: flex;
  background-color: #fff;
  width: auto;
  margin: 0rem 5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  box-shadow: 0px 5px 20px rgba(129, 129, 129, 0.2);
  border-radius: 20px;
}

.profile-picture {
  height: 150px;
}

.user-name {
  font-size: 35px;
  font-weight: 700;
}

.user-email {
  padding: 0.5rem 0;
  font-size: 16px;
  font-weight: 400;
}

.form-heading {
  display: flex;
  padding-left: 1rem;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 800px;
  height: 500px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.input-form {
  min-width: 500px;
}

.profile-picture-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  min-width: 500px;
}
