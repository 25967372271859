@import url('../../../Styles/global.css');

.pagination-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 8px 0 15px 0;
  align-items: center;
  border-radius: 3.6px;
}
.pagination li {
  display: inline;
}

.btn {
  font-size: 22.5px;
  font-weight: 300;
  padding: 0 0.9rem;
  justify-content: center;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.btn:hover {
  background-color: #e9ecef;
  border-radius: 5rem;
  cursor: pointer;
}

.page-link {
  transition: all 0.2s ease-in;
  position: relative;
  float: left;
  padding: 5.4px 10.8px;
  color: var(--color-gray);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.page-link:hover {
  z-index: 2;
  background-color: #f6f6f6;
  border-radius: 2.25rem;
  cursor: pointer;
}

.page-link {
  padding: 0.3375rem 0.675rem;
  border: none;
  text-decoration: none;
}

.active {
  color: var(--color-primary);
}
