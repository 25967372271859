.freelancer-modal-container {
  display: flex;
  width: auto;
}

.freelancer-form-wrapper {
  align-items: center;
  flex-direction: column;
}

.name-wrapper {
  display: flex;
  width: 100%;
}

.freelancer-form-container {
  margin: 2rem 1rem;
}
