@import url('../../Styles/global.css');

.page-container {
  margin: 1.5rem 2rem;
}

.dashboard-container {
  overflow-x: hidden;
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.user-action-btn {
  justify-content: center;
  display: flex;
}

.user-action-btn button {
  margin-left: 10px;
}

.transcription-details-wrapper {
  margin: 1.5rem 1.5rem;
}

.transcription-details-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 35%;
  min-width: 350px;
  color: var(--color-text-secondary);
}

.transcription-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 70%;
}

.audio-transcription-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transcription-container > div {
  margin: 0.75rem;
}
.audio-container {
  width: 35%;
  min-width: 350px;
}

.download-btn {
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
}
.download-btn:hover {
  filter: brightness(80%);
}

.status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
}

.status-wrapper .status {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.editor-wrapper {
  width: 100%;
  flex-direction: column;
}

/* */

.audio-transcription {
  margin-left: auto;
  margin-right: auto;
}

.audio-transcription .media-controls {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray);
  border-radius: 10px;
  padding: 0.15rem;
  flex-direction: column;
  margin-right: auto;
}

.audio-transcription .media-controls .fwd,
.rev,
.play,
.speed {
  padding: 0.5rem;
  fill: #6d1d00;
  cursor: pointer;
}

.audio-transcription .media-controls .play,
.speed {
  position: relative;
}

.audio-transcription .media-controls .play:hover,
.rev:hover,
.fwd:hover {
  fill: #fff;
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.play:active {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.audio-transcription .speed {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.audio-transcription .speed-rate {
  margin-top: 0.5rem;
}

@media (max-width: 1100px) {
  .audio-transcription .media-controls {
    display: flex;
    flex-direction: row;
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;
    height: 40px;
  }

  .audio-transcription .media-controls .fwd,
  .rev,
  .play,
  .speed {
    fill: #6d1d00;
    cursor: pointer;
  }

  .audio-transcription .media-controls .speed {
    position: relative;
    margin-bottom: 1px;
  }

  .audio-transcription .media-controls .play:hover,
  .rev:hover,
  .fwd:hover {
    fill: #fff;
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }

  .play:active {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }

  .audio-transcription .speed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }

  .audio-transcription .speed-rate {
    margin: 0 0.3rem;
  }
}

.btn-close {
  cursor: pointer;
  height: 3.5px;
  width: auto;
  position: absolute;
  right: 0;
  margin-right: 1.35rem;
}

.dropdown-btn {
  font-family: 'Work Sans' !important;
  color: var(--color-gray);
  margin: 0;
  font-size: 1rem;
  padding: 0 !important;
}
.dropdown-btn:hover {
  background: white;
}

.btn-keyboard-shortcuts-container {
  width: 80%;
  margin: 0 !important;
  padding: 0;
}

.btn-keyboard-shortcuts-container .shortcuts {
  background: #f1f1f1;
  padding: 1rem;
  width: 100%;

  margin: auto;
  margin-left: -1rem;
  border-radius: 5px;
  position: relative;
}

.btn-keyboard-shortcuts-container .shortcuts .btn-close-shortcuts {
  background: none;
  border: none;
  color: #2d5380;
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
}

.btn-keyboard-shortcuts-container .shortcuts .line {
  margin: 0.75rem 0;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.btn-keyboard-shortcuts-container .shortcuts .key {
  padding: 7px;
  color: #fff;
  background: #000;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 0.9rem;
}

.btn-keyboard-shortcuts-container .shortcuts .action {
  margin-right: 15px;
  /* padding: 1rem 0; */
}

.btn-keyboard-shortcuts {
  color: #2d5380;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  font-size: 0.9rem;
  font-family: 'Work Sans', sans-serif;
  width: fit-content;
  float: right;
  cursor: pointer;
}

.btn-keyboard-shortcuts svg {
  margin-right: 5px;
}

.btn-keyboard-shortcuts svg path {
  fill: #2d5380;
}

.display-transcriptions {
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 2rem !important; */
  max-height: 490px;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.48);
  justify-content: center;
}

.display-transcriptions::-webkit-scrollbar {
  width: 0;
  display: none;
}

@media (max-width: 1100px) {
  .display-transcriptions {
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    border-radius: 15px;
    padding-bottom: 1rem;
    border: 1.5px solid var(--color-gray);
    justify-content: center;
    overflow-y: scroll;
  }

  .audio-transcription-wrapper {
    flex-direction: column;
  }
}

.assigned-view-wrapper {
  position: absolute;
  z-index: 10;
  left: 40%;
  display: flex;
  margin-left: au;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.assigned-view {
  font-size: 20px;
  font-weight: 600;
  background-color: var(--color-pending);
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: black;
  pointer-events: none;
}

.assigned-view-button {
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.audio-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.audio-disabled:hover {
  color: var(--color-gray);
}

.disabled-wrapper {
  cursor: not-allowed;
}

.center-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.display-transcriptions-disabled {
  opacity: 0.8;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-height: 490px;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.48);
  justify-content: center;
}

.play-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  margin-bottom: 1rem;
}
