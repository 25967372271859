@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --color-text: black;
  --color-text-secondary: #464e5f; /* #2d5380 */
  --color-primary: #40e0cf;
  --color-secondary: #ff754a;

  /* gradients */

  --primary-gradient: linear-gradient(
    90deg,
    rgba(64, 224, 207, 1) 0%,
    rgba(54, 205, 207, 1) 100%
  );
  --secondary-gradient: linear-gradient(
    90deg,
    rgba(255, 117, 74, 1) 0%,
    rgba(255, 177, 42, 1) 100%
  );
  --color-gray: #c3c3c3;
  --orange-gradient: linear-gradient(
    63.17deg,
    #ff754a -16.06%,
    #ffb12a 128.22%
  );

  /* for badges */

  --color-success: #61e786;
  --color-failure: #f47266;

  --color-pending: #ffdd86;

  --color-progress: #95a8ff;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans', sans-serif;
}

p {
  margin: 0 !important;
  color: var(--color-text-secondary);
}
/* specify other tags aswell */

h4 {
  color: var(--color-gray);
  font-size: 14.5px;
  font-weight: 300;
  margin: 0 !important;
}

h3 {
  font-weight: 700;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: var(--color-text);
}
