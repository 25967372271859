@import url('../../Styles/global.css');

.view-none-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-none {
  font-size: 20px;
  font-weight: 600;
  z-index: 10;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pending);
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: black;
  pointer-events: none;
}
