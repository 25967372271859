@import url('../../../../Styles/global.css');

.form-wrapper {
  margin: 0 1.5rem;
  border: 2px solid;
  border-radius: 15px;
  max-width: 400px;
  align-items: center;
  border-color: var(--color-primary);
  padding: 2rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;
}

.button-style {
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  background: var(--secondary-gradient);
  border: none;
  color: white;
  border-radius: 7.5px;
  font-weight: 500;
  padding: 0.75rem 1rem;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.signin-container {
  padding-top: 1.5rem;
  margin: 0.5rem;
}

.button-style:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .form-wrapper {
    max-width: 300px;
  }
}

.form-error {
  font-size: 12px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: var(--color-failure);
}
