@import url('../../Styles/global.css');
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
}

.loader-label {
  padding: 1rem 0;
}
