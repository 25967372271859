@import url('../../Styles/global.css');

.table-wrapper {
  width: 90%;
  margin: 0 auto;
  overflow: auto;
  box-sizing: border-box;
}

.table-wrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}

.footer-wrapper {
  display: flex;
  margin-left: 1rem;
  margin-right: 0.25rem;
  width: max-content;
  flex-direction: row;
  position: absolute;
  bottom: 24px;
  transform: translateY(5px);
  z-index: 3;
  background: white;
}

.tbl {
  width: 100%;
  border-collapse: collapse;
}

.tbl-header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
  font-size: 0.855rem;
}

.tbl::-webkit-scrollbar {
  width: 0;
  display: none;
}

.show-rows {
  padding: 0rem 0.9rem;
  bottom: 0;
}

.table-title {
  padding-left: 0.9rem;
  margin: 0;
}

.btn-wrapper {
  margin-left: auto;
  margin-right: 1.35rem;
}

.multiple-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 35px;
}

.single-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 35px;
}

tr {
  height: 49.5px;
}

th {
  background-color: #f3f3f3;
  text-transform: uppercase;
  line-height: 12.6px;
  letter-spacing: 0.0027em;
  text-align: left;
  padding: 13.5px;
  font-weight: 700;
}

td {
  padding: 0 1rem;
  font-weight: 400;
  color: var(--color-text-secondary);
  font-size: 0.9rem;
  line-height: 16.42px;
  /* font-family: 'Work Sans'; */
}

.sr-no {
  padding-left: 40px;
}

.action-title {
  padding-left: 50px;
}

.table-top {
  display: flex;
  margin-left: auto;
  margin-bottom: 0.75rem;
}

/* Table Scroll */

/* table {
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

table thead {
  flex: 1 1 auto;
  width: calc(100% - 1rem);
}

table tbody {
  flex: 1 1 auto;
  overflow-y: auto;
}

table tbody::-webkit-scrollbar {
  display: none;
}

table tbody tr {
  width: 100%;
}

table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  table thead,
  table tbody tr {
    table-layout: auto;
  }
}

@media (max-width: 600px) {
  table tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  th,
  td {
    min-width: 5rem;
  }

  .table-wrapper {
    width: 100%;
    margin: auto;
  }
} */

/* @media (max-height: 900px) {
  .tbl {
    max-height: 43rem;
  }
}

@media (max-height: 800px) {
  .tbl {
    max-height: 30rem;
  }
}

@media (max-height: 650px) {
  .tbl {
    max-height: 25rem;
  }
}

@media (max-height: 450px) {
  .tbl {
    max-height: 16rem;
  }
} */
