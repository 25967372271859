@import url('../../Styles/global.css');

.btn-style {
  /* width: 100%; */
  display: flex;
  max-width: 100px;
  justify-content: center;
  border: none;
  margin: auto;
  min-width: 72px;
  border-radius: 9px;
  font-weight: 600;
  padding: 0.45rem 0.45rem;
  font-size: 12.6px;
  margin: 0.45rem 0;
  text-align: center;
  font-family: 'Roboto';
  color: white;
}

.default {
  background: #fff;
  content: '-';
}

.completed {
  background: var(--color-success);
  /* color: var(--color-success-text); */
}

.in-progress {
  background: var(--color-progress);
  /* color: var(--color-progress-text); */
}

.not-started {
  background: var(--color-failure);
  /* color: var(--color-failure-text); */
}

.verified {
  background: var(--color-success);
  /* color: var(--color-success-text); */
}

.pending {
  background: var(--color-pending);
  /* color: var(--color-pending-text); */
}

.transcribing {
  background: var(--color-pending);
  /* color: var(--color-pending-text); */
}

.no-fill {
  background-color: transparent;
  padding: 0rem 0.45rem;
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
}
