.right-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: linear-gradient(90deg, rgba(164, 223, 217,0.5) 0%, rgba(54,205,207,0.5) 100%); */
  background-image: linear-gradient(
      145deg,
      rgba(61, 218, 207, 0.25) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    url('../../../../Assets/Images/background.svg');
  background-position: center;
  background-size: cover;
  align-items: center;
  background-repeat: no-repeat;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tagline {
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.image-container {
  max-width: 150px;
  justify-content: center;
}

@media (max-width: 768px) {
  .tagline {
    display: none;
  }

  .image-container {
    display: none;
  }
  .right-container {
    width: 0%;
    height: 0%;
  }
}
