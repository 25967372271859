@import '../../Styles/global.css';

.dashboard-container {
  padding-left: 54px;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  font-family: 'Work Sans';
  .table-wrapper {
    position: absolute;
    left: 114px;
    top: 125px;
    bottom: 1px;
  }
  .topbar-action-tag {
    margin-right: 1rem;
  }
}
