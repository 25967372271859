@import url('../../../../Styles/global.css');

.channel-container {
  position: relative;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 500px;
  border-radius: 10px;
  justify-content: center;
}

.channel-container::-webkit-scrollbar {
  width: 0;
  display: none;
}

.channel-header {
  position: sticky;
  top: 0;
  display: flex;
  margin: 0;
  width: 100%;
  background-color: white;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  align-items: center;
  font-size: 17.5px;
  font-weight: 500;
  z-index: 999;
  justify-content: space-around;
}

.channel-notes-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.channel-notes {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 150px;
  min-width: 250px;
  padding: 1rem;
}

.notes-wrapper {
  position: relative;
  padding: 0.5rem;
  margin: 1rem 0;
  width: 100%;
  width: 500px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid white;
  transition: border 0.2s ease;
}

.notes-wrapper.active-segment {
  border: 2px solid var(--color-primary);
}

.notes-content-container .top {
  display: inline-flex;
  align-items: center;
}

.notes-times {
  display: inline-flex;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 12px;
  top: 5px;
  left: 0;
  margin-right: 10px;
}

.notes-content-container .tag {
  background: #2d5380;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 3px;
  margin-right: 5px;
  display: inline-block;
}

.notes-content {
  display: flex;
  padding: 0.175rem;
  min-width: 150px;
  min-height: 25px;
  font-size: 14px;
}
.notes-content-container {
  cursor: pointer;
}

.empty {
  border: none;
  content: '';
  position: relative;
  min-height: 50px;
  padding: 0.5rem;
  margin: 1rem 0;
  width: 100%;
  width: 500px;
  border-radius: 15px;
}

.empty-notes {
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: auto;
}

@media (max-width: 1100px) {
  .notes-wrapper {
    min-height: 100px;
    padding: 0.5rem;
    margin: 1rem 0;
    width: 100%;
    max-width: 300px;
    border: 1.5px solid var(--color-gray);
    border-radius: 15px;
  }

  .channel-header {
    font-size: 15px;
  }

  .notes-times {
    font-size: 10px;
  }

  .notes-content {
    font-weight: 500;
    font-size: 12px;
  }
}

.notes-actions-wrapper {
  display: flex;
  bottom: 0;
  margin: 0.25rem 0rem 0.25rem 0.25rem;
  /* margin-bottom: 0.25rem;
  margin-right: 0.25rem; */
  right: 0;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.notes-actions {
  padding: 0.125rem;
  margin: 0rem 0.125rem;
  color: #2d5380;
}

.notes-actions:hover {
  cursor: pointer;
  color: var(--color-gray);
}

.transcription-wrapper {
  position: absolute;
  top: 50%;
  left: 40%;
  z-index: 999;
}

.transcription-wrapper > .transcription-text {
  min-width: 500px;
}

.transcription-wrapper > .transcription-text > .text-area-container {
  min-width: 500px;
}

.transcription-wrapper .text-area-container .text-area {
  min-width: 500px;
}

.text-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.notes-actions-wrapper-disabled {
  pointer-events: none;
}

/* REACT TAGS */
.ReactTags__tag {
  background: #2d5380;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 3px;
  margin: 0 5px 10px 0;
  display: inline-block;
}

.ReactTags__tag .ReactTags__remove {
  background: none;
  border: none;
  color: #fff;
  font-size: 0.9rem;
}

.ReactTags__tagInputField {
  padding: 5px;
  font-size: 0.9rem;
  font-family: 'Work Sans', sans-serif;
  border-radius: 5px;
  border: 1px solid #cecece;
}
