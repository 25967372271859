@import url('../../Styles/global.css');

.form-group {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0.5rem 0.5rem;
}

.label-style {
  padding: 0.5rem 0;
  font-weight: 500;
  align-self: flex-start;
}

.input-style {
  height: 37.5px;
  border: solid;
  border-width: thin;
  border-color: #e9e9e9;
  margin: 0.5rem 0;
  background-color: rgb(250, 250, 250);
  border-radius: 7.5px;
  padding: 0 1rem;
  outline-color: var(--color-secondary);
  transition: all 0.2s ease-in;
}

.input-disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.medium {
  min-width: 182.5px;
}

.large {
  min-width: 250px;
  max-width: 350px;
}
