.confirmation-modal-container {
  display: flex;
  width: auto;
}

.confirm-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}

.confirm-action-btn {
  margin: 0 0.35rem;
}

.confirmation-text {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  padding: 1rem 1rem;
}
