@import '../../Styles/global.css';

.switch {
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right: 30px;
  .checkmark {
    background: var(--orange-gradient);
    position: absolute;
    top: -1px;
    left: 0;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    transition: 0.3s;
    &:after {
      content: '';
      position: absolute;
      border: 4px solid #ff9b4a;
      width: 16px;
      height: 16px;
      top: -2.2px;
      left: -2px;
      background: white;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
  input {
    display: none;
    &:checked ~ .checkmark {
      background: var(--primary-gradient);
      &:after {
        border: 4px solid var(--color-primary);
      }
    }
    &:disabled ~ .checkmark {
      opacity: 0.5;
    }
    &:checked ~ .checkmark:after {
      left: 23px;
    }
  }
}
