@import '../../Styles/global.css';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  background: var(--primary-gradient);
  padding-top: 1.62rem;
  justify-content: space-between;
  .logo {
    svg {
      width: 1.53rem;
    }
  }
  .options {
    display: flex;
    margin-top: -17.75rem;
    flex-direction: column;
    a {
      margin: 18px;
      svg {
        color: white;
        width: 1.53rem;
      }
      &.active {
        svg {
          color: var(--color-text-secondary);
        }
      }
    }
  }

  .bottom-options {
    margin-bottom: 2.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile {
      margin-bottom: 1.44rem;
      a {
        img {
          border-radius: 50%;
          width: 1.53rem;
          height: 1.53rem;
          cursor: pointer;
          padding: 2.7px;
          object-fit: cover;
        }
      }
    }
    svg {
      cursor: pointer;
      color: white;
      width: 1.53rem;
    }
  }
}
