@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-text: black;
  --color-text-secondary: #464e5f; /* #2d5380 */
  --color-primary: #40e0cf;
  --color-secondary: #ff754a;

  /* gradients */

  --primary-gradient: linear-gradient(
    90deg,
    rgba(64, 224, 207, 1) 0%,
    rgba(54, 205, 207, 1) 100%
  );
  --secondary-gradient: linear-gradient(
    90deg,
    rgba(255, 117, 74, 1) 0%,
    rgba(255, 177, 42, 1) 100%
  );
  --color-gray: #c3c3c3;
  --orange-gradient: linear-gradient(
    63.17deg,
    #ff754a -16.06%,
    #ffb12a 128.22%
  );

  /* for badges */

  --color-success: #61e786;
  --color-failure: #f47266;

  --color-pending: #ffdd86;

  --color-progress: #95a8ff;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans', sans-serif;
}

p {
  margin: 0 !important;
  color: #464e5f;
  color: var(--color-text-secondary);
}
/* specify other tags aswell */

h4 {
  color: #c3c3c3;
  color: var(--color-gray);
  font-size: 14.5px;
  font-weight: 300;
  margin: 0 !important;
}

h3 {
  font-weight: 700;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: black;
  color: var(--color-text);
}

/* Bootstrap css */

/* Breadcrumb Navigation Component*/

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem;
  margin-left: 1.5rem;
  list-style: none;
  text-decoration: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
  text-decoration: none;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 1rem;
  color: #6c757d;
  text-decoration: none;
  content: '/';
}
.breadcrumb-item.active {
  color: var(--color-primary);
  text-decoration: none;
}

/* Dropdown Component */

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown {
  position: relative;
  border: none;
}

.dropdown-toggle > .btn > .btn-primary {
  color: red;
  margin: 0px !important;
  padding: 0px !important;
}

.dropdown-btn {
  margin: 0 !important;
  padding: 0 !important;
}

.dropdown-toggle {
  white-space: nowrap;
  border: none;
  background-color: #fff;
}

#dropdown-basic {
  padding: 0.5rem 1rem;
  font-size: 10px;
  font-family: 'Work Sans', 'sans-serif';
  font-weight: 500;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  background-color: #fff;
  min-width: 1.5rem;
  font-family: 'Roboto', 'sans-serif';
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  font-size: 1rem;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  color: var(--color-gray);
  cursor: pointer;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff;
  border-radius: 0.5rem;
  width: auto;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  /* background-color: var(--color-secondary); */
}

.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}
/* button close */

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}

/* modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  font-weight: 800;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: 0 0.75rem 0 auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

/* Collapse */

.fade:not(.show) {
  opacity: 0;
}
.collapse {
  visibility: hidden;
}
.collapse.show {
  visibility: visible;
  display: block;
}

.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
}

/* Spinner */

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.login-container {
  min-width: 600px;
  background-color: rgba(255, 255, 255, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 2.5rem;
}

.logo-wrapper {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  align-content: center;
}

.logo {
  padding: 1rem 0;
  max-width: 150px;
}

.welcome {
  font-size: 15px;
  display: inline-block;
  margin: 1.5rem 0 1rem 1.5rem;
}

.title {
  font-size: 25px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .login-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1920px) {
  .logo-wrapper {
    margin-top: 2rem;
  }

  .login-content-wrapper {
    height: 100%;
    padding-bottom: 2.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0.5rem 0.5rem;
}

.label-style {
  padding: 0.5rem 0;
  font-weight: 500;
  align-self: flex-start;
}

.input-style {
  height: 37.5px;
  border: solid;
  border-width: thin;
  border-color: #e9e9e9;
  margin: 0.5rem 0;
  background-color: rgb(250, 250, 250);
  border-radius: 7.5px;
  padding: 0 1rem;
  outline-color: var(--color-secondary);
  transition: all 0.2s ease-in;
}

.input-disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.medium {
  min-width: 182.5px;
}

.large {
  min-width: 250px;
  max-width: 350px;
}

.form-wrapper {
  margin: 0 1.5rem;
  border: 2px solid;
  border-radius: 15px;
  max-width: 400px;
  align-items: center;
  border-color: var(--color-primary);
  padding: 2rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;
}

.button-style {
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  background: var(--secondary-gradient);
  border: none;
  color: white;
  border-radius: 7.5px;
  font-weight: 500;
  padding: 0.75rem 1rem;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.signin-container {
  padding-top: 1.5rem;
  margin: 0.5rem;
}

.button-style:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .form-wrapper {
    max-width: 300px;
  }
}

.form-error {
  font-size: 12px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: var(--color-failure);
}

.right-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background: linear-gradient(90deg, rgba(164, 223, 217,0.5) 0%, rgba(54,205,207,0.5) 100%); */
  background-image: linear-gradient(
      145deg,
      rgba(61, 218, 207, 0.25) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    url(/static/media/background.723eb582.svg);
  background-position: center;
  background-size: cover;
  align-items: center;
  background-repeat: no-repeat;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tagline {
  font-weight: 900;
  font-size: 50px;
  text-align: center;
}

.image-container {
  max-width: 150px;
  justify-content: center;
}

@media (max-width: 768px) {
  .tagline {
    display: none;
  }

  .image-container {
    display: none;
  }
  .right-container {
    width: 0%;
    height: 0%;
  }
}

.left-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.left-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

/* .sidebarContainer {
    min-width: 400px;
    background-color:white;
    height: 100%;
    
} */

.pagination-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 8px 0 15px 0;
  align-items: center;
  border-radius: 3.6px;
}
.pagination li {
  display: inline;
}

.btn {
  font-size: 22.5px;
  font-weight: 300;
  padding: 0 0.9rem;
  justify-content: center;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.btn:hover {
  background-color: #e9ecef;
  border-radius: 5rem;
  cursor: pointer;
}

.page-link {
  transition: all 0.2s ease-in;
  position: relative;
  float: left;
  padding: 5.4px 10.8px;
  color: var(--color-gray);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.page-link:hover {
  z-index: 2;
  background-color: #f6f6f6;
  border-radius: 2.25rem;
  cursor: pointer;
}

.page-link {
  padding: 0.3375rem 0.675rem;
  border: none;
  text-decoration: none;
}

.active {
  color: var(--color-primary);
}

.table-wrapper {
  width: 90%;
  margin: 0 auto;
  overflow: auto;
  box-sizing: border-box;
}

.table-wrapper::-webkit-scrollbar {
  width: 0;
  display: none;
}

.footer-wrapper {
  display: flex;
  margin-left: 1rem;
  margin-right: 0.25rem;
  width: -webkit-max-content;
  width: max-content;
  flex-direction: row;
  position: absolute;
  bottom: 24px;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  z-index: 3;
  background: white;
}

.tbl {
  width: 100%;
  border-collapse: collapse;
}

.tbl-header {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
  font-size: 0.855rem;
}

.tbl::-webkit-scrollbar {
  width: 0;
  display: none;
}

.show-rows {
  padding: 0rem 0.9rem;
  bottom: 0;
}

.table-title {
  padding-left: 0.9rem;
  margin: 0;
}

.btn-wrapper {
  margin-left: auto;
  margin-right: 1.35rem;
}

.multiple-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 35px;
}

.single-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 35px;
}

tr {
  height: 49.5px;
}

th {
  background-color: #f3f3f3;
  text-transform: uppercase;
  line-height: 12.6px;
  letter-spacing: 0.0027em;
  text-align: left;
  padding: 13.5px;
  font-weight: 700;
}

td {
  padding: 0 1rem;
  font-weight: 400;
  color: var(--color-text-secondary);
  font-size: 0.9rem;
  line-height: 16.42px;
  /* font-family: 'Work Sans'; */
}

.sr-no {
  padding-left: 40px;
}

.action-title {
  padding-left: 50px;
}

.table-top {
  display: flex;
  margin-left: auto;
  margin-bottom: 0.75rem;
}

/* Table Scroll */

/* table {
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

table thead {
  flex: 1 1 auto;
  width: calc(100% - 1rem);
}

table tbody {
  flex: 1 1 auto;
  overflow-y: auto;
}

table tbody::-webkit-scrollbar {
  display: none;
}

table tbody tr {
  width: 100%;
}

table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  table thead,
  table tbody tr {
    table-layout: auto;
  }
}

@media (max-width: 600px) {
  table tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  th,
  td {
    min-width: 5rem;
  }

  .table-wrapper {
    width: 100%;
    margin: auto;
  }
} */

/* @media (max-height: 900px) {
  .tbl {
    max-height: 43rem;
  }
}

@media (max-height: 800px) {
  .tbl {
    max-height: 30rem;
  }
}

@media (max-height: 650px) {
  .tbl {
    max-height: 25rem;
  }
}

@media (max-height: 450px) {
  .tbl {
    max-height: 16rem;
  }
} */

.btn-style {
  /* width: 100%; */
  display: flex;
  max-width: 100px;
  justify-content: center;
  border: none;
  margin: auto;
  min-width: 72px;
  border-radius: 9px;
  font-weight: 600;
  padding: 0.45rem 0.45rem;
  font-size: 12.6px;
  margin: 0.45rem 0;
  text-align: center;
  font-family: 'Roboto';
  color: white;
}

.default {
  background: #fff;
  content: '-';
}

.completed {
  background: var(--color-success);
  /* color: var(--color-success-text); */
}

.in-progress {
  background: var(--color-progress);
  /* color: var(--color-progress-text); */
}

.not-started {
  background: var(--color-failure);
  /* color: var(--color-failure-text); */
}

.verified {
  background: var(--color-success);
  /* color: var(--color-success-text); */
}

.pending {
  background: var(--color-pending);
  /* color: var(--color-pending-text); */
}

.transcribing {
  background: var(--color-pending);
  /* color: var(--color-pending-text); */
}

.no-fill {
  background-color: transparent;
  padding: 0rem 0.45rem;
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
}

.action-style {
  /* width: 100%; */
  display: flex;
  width: 30px;
  justify-content: center;
  align-content: center;
  border: none;
  height: auto;
  border-radius: 0.75rem;
  padding: 0.5rem 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.icon-container {
  width: auto;
  height: 22.5px;
}

.view {
  background: var(--primary-gradient);
  color: #ffffff;
}

.edit {
  background: var(--primary-gradient);
  color: #ffffff;
}

.add {
  background: var(--primary-gradient);
  color: #ffffff;
}

.delete {
  background: var(--color-failure);
  color: #ffffff;
}

.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.action-style:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
}

.disabled:hover {
  background: var(--primary-gradient);
}

.custom-btn {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-self: center;
  border: none;
  height: auto;
  font-weight: 700;
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  cursor: pointer;
  font-family: 'Work Sans', 'sans-serif';
}

.btn-fill {
  background: var(--secondary-gradient);
  color: #ffffff;
}

.btn-fill:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.35);
}

.btn-outline,
.btn-outline::after {
  background: #ffffff;
  border: 1.5px solid var(--color-secondary);
  color: var(--color-secondary);
}
.btn-outline:hover {
  cursor: pointer;
  background: var(--secondary-gradient);
  border: 1.5px solid var(--color-secondary);
  color: #ffffff;
}

.disabled {
  opacity: 0.25;
}

.lt {
  font-weight: 500;
  font-family: 'Roboto', 'sans-serif';
}

.btn-fill-primary {
  background: var(--primary-gradient);
  color: #ffffff;
}

.btn-fill-green {
  background-color: var(--color-success);
  color: #ffffff;
}

.confirmation-modal-container {
  display: flex;
  width: auto;
}

.confirm-actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}

.confirm-action-btn {
  margin: 0 0.35rem;
}

.confirmation-text {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  padding: 1rem 1rem;
}

:root{--color-text: black;--color-text-secondary: #464e5f;--color-primary: #40e0cf;--color-secondary: #ff754a;--primary-gradient: linear-gradient(
    90deg,
    rgba(64, 224, 207, 1) 0%,
    rgba(54, 205, 207, 1) 100%
  );--secondary-gradient: linear-gradient(
    90deg,
    rgba(255, 117, 74, 1) 0%,
    rgba(255, 177, 42, 1) 100%
  );--color-gray: #c3c3c3;--orange-gradient: linear-gradient(
    63.17deg,
    #ff754a -16.06%,
    #ffb12a 128.22%
  );--color-success: #61e786;--color-failure: #f47266;--color-pending: #ffdd86;--color-progress: #95a8ff}html{scroll-behavior:smooth}body{font-family:'Work Sans', sans-serif}p{margin:0 !important;color:#464e5f;color:var(--color-text-secondary)}h4{color:#c3c3c3;color:var(--color-gray);font-size:14.5px;font-weight:300;margin:0 !important}h3{font-weight:700;font-size:16px}a{text-decoration:none;color:black;color:var(--color-text)}

.sidebar{position:fixed;top:0;left:0;height:100vh;width:54px;display:flex;flex-direction:column;align-items:center;z-index:5;background:var(--primary-gradient);padding-top:1.62rem;justify-content:space-between}.sidebar .logo svg{width:1.53rem}.sidebar .options{display:flex;margin-top:-17.75rem;flex-direction:column}.sidebar .options a{margin:18px}.sidebar .options a svg{color:white;width:1.53rem}.sidebar .options a.active svg{color:var(--color-text-secondary)}.sidebar .bottom-options{margin-bottom:2.7rem;display:flex;flex-direction:column;align-items:center}.sidebar .bottom-options .profile{margin-bottom:1.44rem}.sidebar .bottom-options .profile a img{border-radius:50%;width:1.53rem;height:1.53rem;cursor:pointer;padding:2.7px;object-fit:cover}.sidebar .bottom-options svg{cursor:pointer;color:white;width:1.53rem}

.topbar{top:0;left:0;background-color:white;height:140px;display:flex;flex-direction:row;justify-content:space-between;padding-left:3.6rem;padding-right:3rem;align-items:center}.topbar .left .title{font-size:2.07rem;font-style:normal;font-weight:700;line-height:37.8px;text-align:left}.topbar .left .subtitle{margin-top:4.5px;font-weight:500}.topbar .right{display:flex;flex-direction:row}

.switch{position:relative;cursor:pointer;-webkit-user-select:none;user-select:none;margin-right:30px}.switch .checkmark{background:var(--orange-gradient);position:absolute;top:-1px;left:0;width:40px;height:20px;border-radius:20px;transition:0.3s}.switch .checkmark:after{content:'';position:absolute;border:4px solid #ff9b4a;width:16px;height:16px;top:-2.2px;left:-2px;background:white;border-radius:50%;transition:0.3s}.switch input{display:none}.switch input:checked ~ .checkmark{background:var(--primary-gradient)}.switch input:checked ~ .checkmark:after{border:4px solid var(--color-primary)}.switch input:disabled ~ .checkmark{opacity:0.5}.switch input:checked ~ .checkmark:after{left:23px}

.freelancer-modal-container {
  display: flex;
  width: auto;
}

.freelancer-form-wrapper {
  align-items: center;
  flex-direction: column;
}

.name-wrapper {
  display: flex;
  width: 100%;
}

.freelancer-form-container {
  margin: 2rem 1rem;
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-secondary);
}

.loader-label {
  padding: 1rem 0;
}

.dashboard-container{padding-left:54px;width:100vw;height:100vh;box-sizing:border-box;font-family:'Work Sans'}.dashboard-container .table-wrapper{position:absolute;left:114px;top:125px;bottom:1px}.dashboard-container .topbar-action-tag{margin-right:1rem}

.transcription-modal-container {
  display: flex;
  width: auto;
}

.transcription-form-wrapper {
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.drop-container {
  margin: 1rem;
  align-items: center;
  justify-content: center;
}
.dropzone {
  padding: 2rem;
  border: 2px solid rgba(255, 117, 74, 0.5);
  border-radius: 10px;
  border-style: dashed;
}

.drop-text {
  color: var(--color-secondary);
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 0.6);
  justify-content: center;
  align-items: center;
}

.drop-language-select-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
}

.drop-language-text {
  font-weight: 500;
}

.language-label {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-weight: 500;
}

.language-inner-label {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-weight: 300;
}

.language-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.language-label-icon {
  padding-left: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  color: var(--color-text-secondary);
}

.disabled {
  pointer-events: none;
}

.view-none-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-none {
  font-size: 20px;
  font-weight: 600;
  z-index: 10;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pending);
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: black;
  pointer-events: none;
}

.wavesurfer-handle {
  background-color: var(--color-primary) !important;
  width: 2px !important;
}

.audio-player {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.audio-player-container {
  position: relative;
}

.channel-title {
  display: flex;
  flex-direction: column;
}

.transcription-text {
  padding: 1rem;
  background-color: #fff;
  border-radius: 15px;
  -webkit-filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.25));
  z-index: 1000;
}
.text-area-container {
  width: 100%;
  height: 100%;
  margin: 1rem 0;
}

.text-area {
  min-width: 400px;
  max-width: 400px;
  height: 100px;
  min-height: 100px;
  width: 400px;
  border: none;
  font-family: 'Work Sans';
  outline: none;
  font-size: 1rem;
  font-weight: 100;
}
.text-area::-webkit-scrollbar {
  display: none;
}

/* .text-area::-webkit-resizer{
    cursor: s-resize;
    

} */

/* .text-area-resize {
  pointer-events: none;
  position: absolute;
  background: none;
  right: 0;
  bottom:0;
  margin-right: 1.5rem;
  margin-bottom: 6rem;
  height: 7px;
  z-index: 1000;
} */

.collapse-bottom-container {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  margin-left: auto;
}

.audio-player .media-controls {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.audio-player .media-controls > .fwd,
.rev,
.play,
.speed {
  padding: 0.5rem;
  fill: #6d1d00;
  cursor: pointer;
}

.audio-player .media-controls > .play,
.speed {
  position: relative;
  bottom: 1px;
}

.audio-player .media-controls > .play:hover,
.rev:hover,
.fwd:hover {
  -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.play:active {
  -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.audio-player .speed {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-player .speed-rate {
  margin: 0 0.25rem;
}

.btn-close {
  cursor: pointer;
  height: 3.5px;
  width: auto;
  position: absolute;
  right: 0;
  margin-right: 1.35rem;
}

.dropdown-btn {
  font-family: 'Work Sans' !important;
  color: var(--color-gray);
  margin: 0;
  font-size: 1rem;
  content: '';
}
.dropdown-btn:hover {
  background: white;
}

.waveform-loader {
  z-index: 100;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 45%;
}

.channel-container {
  position: relative;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 500px;
  border-radius: 10px;
  justify-content: center;
}

.channel-container::-webkit-scrollbar {
  width: 0;
  display: none;
}

.channel-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  margin: 0;
  width: 100%;
  background-color: white;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  align-items: center;
  font-size: 17.5px;
  font-weight: 500;
  z-index: 999;
  justify-content: space-around;
}

.channel-notes-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.channel-notes {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 150px;
  min-width: 250px;
  padding: 1rem;
}

.notes-wrapper {
  position: relative;
  padding: 0.5rem;
  margin: 1rem 0;
  width: 100%;
  width: 500px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid white;
  transition: border 0.2s ease;
}

.notes-wrapper.active-segment {
  border: 2px solid var(--color-primary);
}

.notes-content-container .top {
  display: inline-flex;
  align-items: center;
}

.notes-times {
  display: inline-flex;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 12px;
  top: 5px;
  left: 0;
  margin-right: 10px;
}

.notes-content-container .tag {
  background: #2d5380;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 3px;
  margin-right: 5px;
  display: inline-block;
}

.notes-content {
  display: flex;
  padding: 0.175rem;
  min-width: 150px;
  min-height: 25px;
  font-size: 14px;
}
.notes-content-container {
  cursor: pointer;
}

.empty {
  border: none;
  content: '';
  position: relative;
  min-height: 50px;
  padding: 0.5rem;
  margin: 1rem 0;
  width: 100%;
  width: 500px;
  border-radius: 15px;
}

.empty-notes {
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: auto;
}

@media (max-width: 1100px) {
  .notes-wrapper {
    min-height: 100px;
    padding: 0.5rem;
    margin: 1rem 0;
    width: 100%;
    max-width: 300px;
    border: 1.5px solid var(--color-gray);
    border-radius: 15px;
  }

  .channel-header {
    font-size: 15px;
  }

  .notes-times {
    font-size: 10px;
  }

  .notes-content {
    font-weight: 500;
    font-size: 12px;
  }
}

.notes-actions-wrapper {
  display: flex;
  bottom: 0;
  margin: 0.25rem 0rem 0.25rem 0.25rem;
  /* margin-bottom: 0.25rem;
  margin-right: 0.25rem; */
  right: 0;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.notes-actions {
  padding: 0.125rem;
  margin: 0rem 0.125rem;
  color: #2d5380;
}

.notes-actions:hover {
  cursor: pointer;
  color: var(--color-gray);
}

.transcription-wrapper {
  position: absolute;
  top: 50%;
  left: 40%;
  z-index: 999;
}

.transcription-wrapper > .transcription-text {
  min-width: 500px;
}

.transcription-wrapper > .transcription-text > .text-area-container {
  min-width: 500px;
}

.transcription-wrapper .text-area-container .text-area {
  min-width: 500px;
}

.text-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.notes-actions-wrapper-disabled {
  pointer-events: none;
}

/* REACT TAGS */
.ReactTags__tag {
  background: #2d5380;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 3px;
  margin: 0 5px 10px 0;
  display: inline-block;
}

.ReactTags__tag .ReactTags__remove {
  background: none;
  border: none;
  color: #fff;
  font-size: 0.9rem;
}

.ReactTags__tagInputField {
  padding: 5px;
  font-size: 0.9rem;
  font-family: 'Work Sans', sans-serif;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.page-container {
  margin: 1.5rem 2rem;
}

.dashboard-container {
  overflow-x: hidden;
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.user-action-btn {
  justify-content: center;
  display: flex;
}

.user-action-btn button {
  margin-left: 10px;
}

.transcription-details-wrapper {
  margin: 1.5rem 1.5rem;
}

.transcription-details-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 35%;
  min-width: 350px;
  color: var(--color-text-secondary);
}

.transcription-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 70%;
}

.audio-transcription-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transcription-container > div {
  margin: 0.75rem;
}
.audio-container {
  width: 35%;
  min-width: 350px;
}

.download-btn {
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
}
.download-btn:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
}

.status-wrapper .status {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.editor-wrapper {
  width: 100%;
  flex-direction: column;
}

/* */

.audio-transcription {
  margin-left: auto;
  margin-right: auto;
}

.audio-transcription .media-controls {
  display: flex;
  width: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray);
  border-radius: 10px;
  padding: 0.15rem;
  flex-direction: column;
  margin-right: auto;
}

.audio-transcription .media-controls .fwd,
.rev,
.play,
.speed {
  padding: 0.5rem;
  fill: #6d1d00;
  cursor: pointer;
}

.audio-transcription .media-controls .play,
.speed {
  position: relative;
}

.audio-transcription .media-controls .play:hover,
.rev:hover,
.fwd:hover {
  fill: #fff;
  -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.play:active {
  -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.audio-transcription .speed {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.audio-transcription .speed-rate {
  margin-top: 0.5rem;
}

@media (max-width: 1100px) {
  .audio-transcription .media-controls {
    display: flex;
    flex-direction: row;
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;
    height: 40px;
  }

  .audio-transcription .media-controls .fwd,
  .rev,
  .play,
  .speed {
    fill: #6d1d00;
    cursor: pointer;
  }

  .audio-transcription .media-controls .speed {
    position: relative;
    margin-bottom: 1px;
  }

  .audio-transcription .media-controls .play:hover,
  .rev:hover,
  .fwd:hover {
    fill: #fff;
    -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
            filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }

  .play:active {
    -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
            filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
      brightness(104%) contrast(97%);
  }

  .audio-transcription .speed {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }

  .audio-transcription .speed-rate {
    margin: 0 0.3rem;
  }
}

.btn-close {
  cursor: pointer;
  height: 3.5px;
  width: auto;
  position: absolute;
  right: 0;
  margin-right: 1.35rem;
}

.dropdown-btn {
  font-family: 'Work Sans' !important;
  color: var(--color-gray);
  margin: 0;
  font-size: 1rem;
  padding: 0 !important;
}
.dropdown-btn:hover {
  background: white;
}

.btn-keyboard-shortcuts-container {
  width: 80%;
  margin: 0 !important;
  padding: 0;
}

.btn-keyboard-shortcuts-container .shortcuts {
  background: #f1f1f1;
  padding: 1rem;
  width: 100%;

  margin: auto;
  margin-left: -1rem;
  border-radius: 5px;
  position: relative;
}

.btn-keyboard-shortcuts-container .shortcuts .btn-close-shortcuts {
  background: none;
  border: none;
  color: #2d5380;
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
}

.btn-keyboard-shortcuts-container .shortcuts .line {
  margin: 0.75rem 0;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.btn-keyboard-shortcuts-container .shortcuts .key {
  padding: 7px;
  color: #fff;
  background: #000;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 0.9rem;
}

.btn-keyboard-shortcuts-container .shortcuts .action {
  margin-right: 15px;
  /* padding: 1rem 0; */
}

.btn-keyboard-shortcuts {
  color: #2d5380;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  font-size: 0.9rem;
  font-family: 'Work Sans', sans-serif;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  cursor: pointer;
}

.btn-keyboard-shortcuts svg {
  margin-right: 5px;
}

.btn-keyboard-shortcuts svg path {
  fill: #2d5380;
}

.display-transcriptions {
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 2rem !important; */
  max-height: 490px;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.48);
  justify-content: center;
}

.display-transcriptions::-webkit-scrollbar {
  width: 0;
  display: none;
}

@media (max-width: 1100px) {
  .display-transcriptions {
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-height: 400px;
    border-radius: 15px;
    padding-bottom: 1rem;
    border: 1.5px solid var(--color-gray);
    justify-content: center;
    overflow-y: scroll;
  }

  .audio-transcription-wrapper {
    flex-direction: column;
  }
}

.assigned-view-wrapper {
  position: absolute;
  z-index: 10;
  left: 40%;
  display: flex;
  margin-left: au;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.assigned-view {
  font-size: 20px;
  font-weight: 600;
  background-color: var(--color-pending);
  padding: 0.5rem 1rem;
  border-radius: 15px;
  color: black;
  pointer-events: none;
}

.assigned-view-button {
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.audio-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.audio-disabled:hover {
  color: var(--color-gray);
}

.disabled-wrapper {
  cursor: not-allowed;
}

.center-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.display-transcriptions-disabled {
  opacity: 0.8;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  max-height: 490px;
  padding: 1rem;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.48);
  justify-content: center;
}

.play-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  margin-bottom: 1rem;
}

.profile-container {
  display: flex;
  background-color: #fff;
  width: auto;
  margin: 0rem 5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  box-shadow: 0px 5px 20px rgba(129, 129, 129, 0.2);
  border-radius: 20px;
}

.profile-picture {
  height: 150px;
}

.user-name {
  font-size: 35px;
  font-weight: 700;
}

.user-email {
  padding: 0.5rem 0;
  font-size: 16px;
  font-weight: 400;
}

.form-heading {
  display: flex;
  padding-left: 1rem;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 800px;
  height: 500px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.input-form {
  min-width: 500px;
}

.profile-picture-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  min-width: 500px;
}

