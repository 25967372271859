@import url('../../Styles/global.css');

.action-style {
  /* width: 100%; */
  display: flex;
  width: 30px;
  justify-content: center;
  align-content: center;
  border: none;
  height: auto;
  border-radius: 0.75rem;
  padding: 0.5rem 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
  transform: scale(0.8);
}

.icon-container {
  width: auto;
  height: 22.5px;
}

.view {
  background: var(--primary-gradient);
  color: #ffffff;
}

.edit {
  background: var(--primary-gradient);
  color: #ffffff;
}

.add {
  background: var(--primary-gradient);
  color: #ffffff;
}

.delete {
  background: var(--color-failure);
  color: #ffffff;
}

.disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.action-style:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.5);
}

.disabled:hover {
  background: var(--primary-gradient);
}
