@import '../../Styles/global.css';

.topbar {
  top: 0;
  left: 0;
  background-color: white;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 3.6rem;
  padding-right: 3rem;
  align-items: center;
  .left {
    .title {
      font-size: 2.07rem;
      font-style: normal;
      font-weight: 700;
      line-height: 37.8px;
      text-align: left;
    }
    .subtitle {
      margin-top: 4.5px;
      font-weight: 500;
    }
  }
  .right {
    display: flex;
    flex-direction: row;
  }
}
