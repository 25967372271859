@import url('../../../../Styles/global.css');

.transcription-modal-container {
  display: flex;
  width: auto;
}

.transcription-form-wrapper {
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.drop-container {
  margin: 1rem;
  align-items: center;
  justify-content: center;
}
.dropzone {
  padding: 2rem;
  border: 2px solid rgba(255, 117, 74, 0.5);
  border-radius: 10px;
  border-style: dashed;
}

.drop-text {
  color: var(--color-secondary);
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 0.6);
  justify-content: center;
  align-items: center;
}

.drop-language-select-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
}

.drop-language-text {
  font-weight: 500;
}

.language-label {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-weight: 500;
}

.language-inner-label {
  color: var(--color-text-secondary);
  font-size: 1rem;
  font-weight: 300;
}

.language-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.language-label-icon {
  padding-left: 0.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  color: var(--color-text-secondary);
}

.disabled {
  pointer-events: none;
}
