@import url('../../../../Styles/global.css');

.login-container {
  min-width: 600px;
  background-color: rgba(255, 255, 255, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: 2.5rem;
}

.logo-wrapper {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  align-content: center;
}

.logo {
  padding: 1rem 0;
  max-width: 150px;
}

.welcome {
  font-size: 15px;
  display: inline-block;
  margin: 1.5rem 0 1rem 1.5rem;
}

.title {
  font-size: 25px;
  font-weight: 800;
}

@media (max-width: 768px) {
  .login-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1920px) {
  .logo-wrapper {
    margin-top: 2rem;
  }

  .login-content-wrapper {
    height: 100%;
    padding-bottom: 2.5rem;
  }
}
