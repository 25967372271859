@import url('../../../../Styles/global.css');

.wavesurfer-handle {
  background-color: var(--color-primary) !important;
  width: 2px !important;
}

.audio-player {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.audio-player-container {
  position: relative;
}

.channel-title {
  display: flex;
  flex-direction: column;
}

.transcription-text {
  padding: 1rem;
  background-color: #fff;
  border-radius: 15px;
  filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.25));
  z-index: 1000;
}
.text-area-container {
  width: 100%;
  height: 100%;
  margin: 1rem 0;
}

.text-area {
  min-width: 400px;
  max-width: 400px;
  height: 100px;
  min-height: 100px;
  width: 400px;
  border: none;
  font-family: 'Work Sans';
  outline: none;
  font-size: 1rem;
  font-weight: 100;
}
.text-area::-webkit-scrollbar {
  display: none;
}

/* .text-area::-webkit-resizer{
    cursor: s-resize;
    

} */

/* .text-area-resize {
  pointer-events: none;
  position: absolute;
  background: none;
  right: 0;
  bottom:0;
  margin-right: 1.5rem;
  margin-bottom: 6rem;
  height: 7px;
  z-index: 1000;
} */

.collapse-bottom-container {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  margin-left: auto;
}

.audio-player .media-controls {
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.audio-player .media-controls > .fwd,
.rev,
.play,
.speed {
  padding: 0.5rem;
  fill: #6d1d00;
  cursor: pointer;
}

.audio-player .media-controls > .play,
.speed {
  position: relative;
  bottom: 1px;
}

.audio-player .media-controls > .play:hover,
.rev:hover,
.fwd:hover {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.play:active {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg)
    brightness(104%) contrast(97%);
}

.audio-player .speed {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-player .speed-rate {
  margin: 0 0.25rem;
}

.btn-close {
  cursor: pointer;
  height: 3.5px;
  width: auto;
  position: absolute;
  right: 0;
  margin-right: 1.35rem;
}

.dropdown-btn {
  font-family: 'Work Sans' !important;
  color: var(--color-gray);
  margin: 0;
  font-size: 1rem;
  content: '';
}
.dropdown-btn:hover {
  background: white;
}

.waveform-loader {
  z-index: 100;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 45%;
}
