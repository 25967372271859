.left-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.left-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}

/* .sidebarContainer {
    min-width: 400px;
    background-color:white;
    height: 100%;
    
} */
